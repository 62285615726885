import { Box, Container, Flex, Icon, Link, Text } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { FiveStars } from 'components/common/icons/assets/FiveStars';
import Image from 'next/image';
import { imageDimensions, parseBreakpointStr } from 'utils/storyblok';

export default function CustomersSection({
  size,
  title,
  logos,
  showTitle,
  showReviews,
  reviews,
  layerStyle = 'white',
}) {
  const logoFilters = {
    white: 'none',
    'dark-blue': 'brightness(0) invert(1)',
  };

  return (
    <Container as="section" size={size}>
      {showTitle && (
        <Text
          as="h2"
          variant="uppercase"
          size="xs"
          textAlign="center"
          opacity=".5"
          mb={{ base: '24px', desktop: '32px' }}
        >
          {title}
        </Text>
      )}
      {showReviews && reviews?.length > 0 && (
        <Flex justifyContent="center" direction={{ base: 'column', desktop: 'row' }} mb="28px">
          {reviews.map((review, i) => (
            <Link
              key={i}
              href={review.link.url}
              target="_blank"
              rel="noreferrer"
              variant="accent"
              fontWeight="500"
              textTransform="uppercase"
              display="inline-flex"
              justifyContent="center"
              fontSize="sm"
              letterSpacing=".5px"
              mx="20px"
              mb="20px"
            >
              {review.text}
              <Flex as="span" ml="15px">
                <Icon as={FiveStars} width="112px" />
              </Flex>
            </Link>
          ))}
        </Flex>
      )}
      <Slider>
        <SlideTrack logosLength={logos.length}>
          {logos?.concat(logos).map((logo, i) => (
            <Slide key={i}>
              <Box as="figure" filter={logoFilters[layerStyle]}>
                <Image
                  src={logo.image.filename}
                  alt={logo.image.alt}
                  height={parseBreakpointStr(logo.height)}
                  width={
                    (imageDimensions(logo.image.filename).width * logo.height) /
                    imageDimensions(logo.image.filename).height
                  }
                />
              </Box>
            </Slide>
          ))}
        </SlideTrack>
      </Slider>
    </Container>
  );
}

// Animation
const scroll = keyframes`
  0% { transform: translateX(0); }
	100% { transform: translateX(-50%)}
`;

const Slider = styled.div`
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 0;
  padding: 0;

  &::before,
  &::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
    height: 100px;
    position: absolute;
    width: 50px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }
`;

const SlideTrack = styled.div<{ logosLength: number }>`
  animation: ${scroll} ${({ logosLength }) => `${logosLength * 3}s`} linear infinite;
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
  margin: 0;
  font-size: 0;
  display: flex;
  justify-content: space-between;
  width: fit-content;
`;

const Slide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 20px;
  min-width: 120px;
`;
