import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { VFC } from 'react';
import * as _ from 'lodash';
import { Storyblok } from 'types';
import Image from 'next/image';
import { imageDimensions } from 'utils/storyblok';
import ResponsiveScrollableWrapper from 'components/common/ResponsiveScrollableWrapper';

type Props = {
  title: string;
  description: string;
  images: Storyblok.Image[];
  layerStyle: string;
};

const CustomerKeyAdvantages: VFC<Props> = ({ title, description, images, layerStyle = 'default' }) => {
  return (
    <Container as="section" layerStyle={layerStyle} backgroundColor="#FFFFFF">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Heading as="h2" mb={{ base: '32px', desktop: '60px' }}>
          {title}
        </Heading>
        <ResponsiveScrollableWrapper
          width="100%"
          desktopColumns="max-content"
          cardWidth="max-content"
          gap="16px"
          wrap="nowrap"
        >
          {_.map(images, (image: Storyblok.Image, index) => (
            <Image
              src={image.filename}
              width={imageDimensions(image.filename).width}
              height={imageDimensions(image.filename).height}
              alt={image.alt}
              key={index}
            />
          ))}
        </ResponsiveScrollableWrapper>
        <Text fontWeight={400} fontSize="13px" lineHeight="16px" mt="60px">
          {description}
        </Text>
      </Box>
    </Container>
  );
};

export default CustomerKeyAdvantages;
