import {
  Box, Button, Checkbox,
  CheckboxGroup, Container, Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay, Flex, Grid, Heading, Link, Radio,
  RadioGroup, Stack, Text, useDisclosure, useMediaQuery, VStack
} from '@chakra-ui/react';
import { DynamicIcon } from 'components/common/icons/DynamicIcon';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import { FC, useEffect, useMemo, useState } from 'react';
import { theme } from 'theme';
import { DynamicComponent } from '.';

/* Pagination */

type PaginationProps = {
  length: number,
  value: number,
  onChange: (newValue: number) => void,
}

const Pagination: FC<PaginationProps> = ({
  length,
  value,
  onChange,
}) => {
  if (!length) {
    return (
      <Box />
    );
  }
  return (
    <Box width="full">
      <Box
        display={{ base: "none", tablet: "flex" }}
        alignItems="center"
        justifyContent="center"
        height="32px"
        width="full"
        gap="16px"
      >
        <Link
          color={theme.colors.gray[70]}
          fontSize={theme.fontSizes.md}
          onClick={() => onChange(Math.max(value - 1, 0))}
        >
          Previous
        </Link>
        {
          Array.from({ length }).map((_, i) => (
            <Box
              key={i}
              bgColor={i === value ? "sapphire.10" : ""}
              height="32px"
              width="32px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              rounded="full"
            >
              <Link
                color={theme.colors.gray[70]}
                fontSize={theme.fontSizes.md}
                onClick={() => onChange(i)}
              >
                {i + 1}
              </Link>
            </Box>
          ))
        }
        <Link
          color={theme.colors.gray[70]}
          fontSize={theme.fontSizes.md}
          onClick={() => onChange(Math.min(value + 1, length - 1))}
        >
          Next
        </Link>
      </Box>
    </Box>
  )
}

/* CustomerStoriesFilters */

interface CustomerStoriesFiltersProps {
  title: string;
  stories: any;
}

const CustomerStoriesFilters: FC<CustomerStoriesFiltersProps> = ({ title, stories }) => {
  const { isOpen: isOpenOutcome, onOpen: onOpenOutcome, onClose: onCloseOutcome } = useDisclosure();
  const { isOpen: isOpenFeature, onOpen: onOpenFeature, onClose: onCloseFeature } = useDisclosure();
  const { isOpen: isOpenSize, onOpen: onOpenSize, onClose: onCloseSize } = useDisclosure();
  const { isOpen: isOpenIndustry, onOpen: onOpenIndustry, onClose: onCloseIndustry } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [filters, setFilters] = useState({
    outcomeFilter: [] as string[],
    featureFilter: [] as string[],
    sizeFilter: [] as string[],
    industryFilter: [] as string[],
  });
  const [outcomeFilterNumber, setOutcomeFilterNumber] = useState(0);
  const [featureFilterNumber, setFeatureFilterNumber] = useState(0);
  const [sizeFilterNumber, setSizeFilterNumber] = useState(0);
  const [industryFilterNumber, setIndustryFilterNumber] = useState(0);

  const selectedFiltersArray = useMemo(
    () =>
      [...filters.outcomeFilter, ...filters.featureFilter, ...filters.sizeFilter, ...filters.industryFilter].filter(
        Boolean,
      ),
    [filters],
  );

  const [page, setPage] = useState(0);

  const filteredStories = useMemo(() => {
    return filter(stories, (story: any) => {
      if (selectedFiltersArray.length === 0) return true;
      const storySizeFilter = isArray(story.sizeFilter) ? story.sizeFilter[0] : story.sizeFilter;
      const storyIndustryFilter = isArray(story.industryFilter) ? story.industryFilter : [story.industryFilter];
      const storyAttributes = [
        ...(story.outcomeFilter || []),
        ...(story.featureFilter || []),
        storySizeFilter,
        ...storyIndustryFilter,
      ];
      return selectedFiltersArray.every((filter) => storyAttributes.includes(filter));
    });
  }, [selectedFiltersArray, stories]);

  const handleFilterChange = (filterType: string, value: string | string[]) => {
    setPage(0);
    setFilters((prevFilters) => {
      const updatedFilters = {
        ...prevFilters,
        [filterType]: isArray(value) ? value : [value],
      };

      if (filterType === 'sizeFilter' && typeof value === 'string') {
        updatedFilters.sizeFilter = [value];
      }

      return updatedFilters;
    });
  };

  const handleSizeFilterClick = (value: string) => {
    setFilters((prevFilters) => {
      const currentFilterValue = prevFilters.sizeFilter[0];
      return {
        ...prevFilters,
        sizeFilter: currentFilterValue === value ? [] : [value],
      };
    });
  };

  useEffect(() => {
    const outcomeFilters = ['reducedDSO', 'savedTime', 'reducedBadDebt'];
    const sizeFilters = ['upTo50', '50to200', '201to500', 'over500'];
    const industryFilters = [
      "marketingTech",
      "hrTech",
      "financeTech",
      "ecommerceTech",
      "productManagementTech",
      "manufacturingAndLogistics",
      "transportation",
      "storageSolutions",
      "hrConsulting",
      "fitness",
    ];
    const featureFilters = ['Collections', 'Analytics', 'Payments'];

    let newOutcomeCount = 0;
    let newSizeCount = 0;
    let newIndustryCount = 0;
    let newFeatureCount = 0;

    selectedFiltersArray.forEach((value) => {
      if (outcomeFilters.includes(value)) {
        newOutcomeCount += 1;
      } else if (sizeFilters.includes(value)) {
        newSizeCount += 1;
      } else if (industryFilters.includes(value)) {
        newIndustryCount += 1;
      } else if (featureFilters.includes(value)) {
        newFeatureCount += 1;
      }
    });

    setOutcomeFilterNumber(newOutcomeCount);
    setSizeFilterNumber(newSizeCount);
    setIndustryFilterNumber(newIndustryCount);
    setFeatureFilterNumber(newFeatureCount);
  }, [
    selectedFiltersArray,
    setOutcomeFilterNumber,
    setSizeFilterNumber,
    setIndustryFilterNumber,
    setFeatureFilterNumber,
  ]);

  const resetFilters = () => {
    setFilters({
      outcomeFilter: [],
      featureFilter: [],
      sizeFilter: [],
      industryFilter: [],
    });
  };

  return (
    <Box as="section" width="100%" id="stories">
      <Container maxW="container.xl">
        <Heading as="h1" mb={{ base: '24px', desktop: '48px' }}>
          {title}
        </Heading>
        <Flex direction={{ base: 'column', desktop: 'row' }}>
          {/* Sidebar for Filters */}
          {!isMobile ? (
            <Box
              as="aside"
              flex="0 0 314px"
              mr="32px"
              backgroundColor={theme.colors.neutral[100]}
              p="24px"
              borderRadius="8px"
              shadow={theme.shadows.xl}
              display={{ base: 'none', desktop: 'block' }}
              height="fit-content"
            >
              <Flex justifyContent="space-between" mb="24px">
                <Heading fontSize="12px" fontWeight="500" lineHeight="20px" color="#0B254B80" letterSpacing="4%">
                  Filter Customer Stories
                </Heading>
                <Link color={theme.colors.gray[70]} fontSize={theme.fontSizes.md} mb={4} onClick={resetFilters}>
                  Reset
                </Link>
              </Flex>

              {/* Outcome Filter */}
              <Box mb={6}>
                <Heading fontSize="15px" fontWeight="500" lineHeight="20px" color="#0B254B" mb="8px">
                  <DynamicIcon icon="Logout" width="16px" height="16px" mr="12px" />
                  Outcome
                </Heading>
                <CheckboxGroup
                  value={filters.outcomeFilter}
                  onChange={(value) => handleFilterChange('outcomeFilter', value as string[])}
                >
                  <Stack direction="column">
                    <Checkbox value="reducedDSO" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Reduced DSO
                      </Text>
                    </Checkbox>
                    <Checkbox value="savedTime" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Saved Time
                      </Text>
                    </Checkbox>
                    <Checkbox value="reducedBadDebt" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Reduced Bad Debt
                      </Text>
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
              </Box>

              {/* Feature Filter */}
              <Box mb={6}>
                <Heading fontSize="15px" fontWeight="500" lineHeight="20px" color="#0B254B" mb="8px">
                  <DynamicIcon icon="AwardStar" width="16px" height="16px" mr="12px" />
                  Feature
                </Heading>
                <CheckboxGroup
                  value={filters.featureFilter}
                  onChange={(value) => handleFilterChange('featureFilter', value as string[])}
                >
                  <Stack direction="column">
                    <Checkbox value="Collections" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Collections
                      </Text>
                    </Checkbox>
                    <Checkbox value="Analytics" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Analytics
                      </Text>
                    </Checkbox>
                    <Checkbox value="Payments" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Payments
                      </Text>
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
              </Box>

              {/* Size Filter */}
              <Box mb={6}>
                <Heading fontSize="15px" fontWeight="500" lineHeight="20px" color="#0B254B" mb="8px">
                  <DynamicIcon icon="Groups" width="16px" height="16px" mr="12px" />
                  Size
                </Heading>
                <RadioGroup value={filters.sizeFilter[0] || ''}>
                  <Stack direction="column">
                    <Box onClick={() => handleSizeFilterClick('upTo50')} cursor="pointer">
                      <Radio value="upTo50" py="4px" onClick={() => handleSizeFilterClick('upTo50')}>
                        <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B" onClick={() => handleSizeFilterClick('upTo50')}>
                          Up to 50 employees
                        </Text>
                      </Radio>
                    </Box>
                    <Box onClick={() => handleSizeFilterClick('50to200')} cursor="pointer">
                      <Radio value="50to200" py="4px" onClick={() => handleSizeFilterClick('50to200')}>
                        <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B" onClick={() => handleSizeFilterClick('50to200')}>
                          51-200 employees
                        </Text>
                      </Radio>
                    </Box>
                    <Box onClick={() => handleSizeFilterClick('201to500')} cursor="pointer">
                      <Radio value="201to500" py="4px" onClick={() => handleSizeFilterClick('201to500')}>
                        <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B" onClick={() => handleSizeFilterClick('201to500')}>
                          201-500 employees
                        </Text>
                      </Radio>
                    </Box>
                    <Box onClick={() => handleSizeFilterClick('over500')} cursor="pointer">
                      <Radio value="over500" py="4px" onClick={() => handleSizeFilterClick('over500')}>
                        <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B" onClick={() => handleSizeFilterClick('over500')}>
                          Over 500 employees
                        </Text>
                      </Radio>
                    </Box>
                  </Stack>
                </RadioGroup>
              </Box>

              {/* Industry Filter: Hidden for now */}
              {/* <Box mb={6}>
                <Heading fontSize="15px" fontWeight="500" lineHeight="20px" color="#0B254B" mb="8px">
                  <DynamicIcon icon="Factory" width="16px" height="16px" mr="12px" />
                  Industry
                </Heading>
                <CheckboxGroup
                  value={filters.industryFilter}
                  onChange={(value) => handleFilterChange('industryFilter', value as string[])}
                >
                  <Stack direction="column">
                    <Checkbox value="marketingTech" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Marketing Tech
                      </Text>
                    </Checkbox>
                    <Checkbox value="hrTech" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        HR Tech
                      </Text>
                    </Checkbox>
                    <Checkbox value="financeTech" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Finance Tech
                      </Text>
                    </Checkbox>
                    <Checkbox value="ecommerceTech" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Ecommerce Tech
                      </Text>
                    </Checkbox>
                    <Checkbox value="productManagementTech" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Product Management Tech
                      </Text>
                    </Checkbox>
                    <Checkbox value="manufacturingAndLogistics" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Manufacturing & Logistics
                      </Text>
                    </Checkbox>
                    <Checkbox value="transportation" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Transportation
                      </Text>
                    </Checkbox>
                    <Checkbox value="storageSolutions" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Storage Solutions
                      </Text>
                    </Checkbox>
                    <Checkbox value="hrConsulting" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        HR Consulting
                      </Text>
                    </Checkbox>
                    <Checkbox value="fitness" py="4px">
                      <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                        Fitness
                      </Text>
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
              </Box> */}
            </Box>
          ) : (
            <Box>
              {/* Mobile Filter Buttons */}
              <Flex justifyContent="space-between" mb="16px">
                <Heading fontSize="12px" fontWeight="500" lineHeight="20px" color="#0B254B80" letterSpacing="4%">
                  Filter Customer Stories
                </Heading>
                <Link color={theme.colors.gray[70]} fontSize={theme.fontSizes.md} mb={4} onClick={resetFilters}>
                  <Text fontWeight="400" fontSize="15px" lineHeight="20px">
                    Reset
                  </Text>
                </Link>
              </Flex>
              <Grid templateColumns="repeat(2, 1fr)" gap="8px" mb="32px">
                {/* Outcome Button */}
                <Button
                  variant="outline"
                  borderRadius="8px"
                  borderColor="#DCE0E7"
                  textColor="#546681"
                  fontSize="14px"
                  lineHeight="16px"
                  fontWeight="500"
                  onClick={onOpenOutcome}
                  leftIcon={<DynamicIcon icon="Logout" width="16px" height="16px" />}
                >
                  Outcome
                  <Text fontWeight="400" fontSize="14px" lineHeight="16px" pl="24px">
                    {outcomeFilterNumber === 0 ? 'Any' : outcomeFilterNumber.toString()}
                  </Text>
                </Button>
                {/* Feature Button */}
                <Button
                  variant="outline"
                  borderRadius="8px"
                  borderColor="#DCE0E7"
                  textColor="#546681"
                  fontSize="14px"
                  lineHeight="16px"
                  fontWeight="500"
                  onClick={onOpenFeature}
                  leftIcon={<DynamicIcon icon="AwardStar" width="16px" height="16px" />}
                >
                  Feature
                  <Text fontWeight="400" fontSize="14px" lineHeight="16px" pl="24px">
                    {featureFilterNumber === 0 ? 'Any' : featureFilterNumber.toString()}
                  </Text>
                </Button>
                {/* Size Button */}
                <Button
                  variant="outline"
                  borderRadius="8px"
                  borderColor="#DCE0E7"
                  textColor="#546681"
                  fontSize="14px"
                  lineHeight="16px"
                  fontWeight="500"
                  onClick={onOpenSize}
                  leftIcon={<DynamicIcon icon="Groups" width="16px" height="16px" />}
                >
                  Size
                  <Text fontWeight="400" fontSize="14px" lineHeight="16px" pl="24px">
                    {sizeFilterNumber === 0 ? 'Any' : sizeFilterNumber.toString()}
                  </Text>
                </Button>
                {/* Industry Button */}
                {/* <Button
                  variant="outline"
                  borderRadius="8px"
                  borderColor="#DCE0E7"
                  textColor="#546681"
                  fontSize="14px"
                  lineHeight="16px"
                  fontWeight="500"
                  onClick={onOpenIndustry}
                  leftIcon={<DynamicIcon icon="Factory" width="16px" height="16px" />}
                >
                  Industry
                  <Text fontWeight="400" fontSize="14px" lineHeight="16px" pl="24px">
                    {industryFilterNumber === 0 ? 'Any' : industryFilterNumber.toString()}
                  </Text>
                </Button> */}
              </Grid>
              {/* Drawers for Mobile Filters */}
              <Drawer placement="bottom" onClose={onCloseOutcome} isOpen={isOpenOutcome}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerBody px="24px" py="16px" backgroundColor={theme.colors.neutral[100]}>
                    <VStack>
                      <Flex justifyContent="space-between" py="8px" width="100%">
                        <Flex alignItems="center">
                          <DynamicIcon icon="Logout" width="16px" height="16px" />
                          <Text fontWeight="500" fontSize="15px" lineHeight="20px" color="black" pl="12px">
                            Outcome
                          </Text>
                        </Flex>
                        <Button
                          variant="link"
                          onClick={() => {
                            onCloseOutcome();
                            resetFilters();
                          }}
                        >
                          Cancel
                        </Button>
                      </Flex>
                      <Flex width="100%" py="8px">
                        <CheckboxGroup
                          value={filters.outcomeFilter}
                          onChange={(value) => handleFilterChange('outcomeFilter', value as string[])}
                        >
                          <Stack direction="column">
                            <Checkbox
                              value="reducedDSO"
                              py="4px"
                              _before={{ bg: 'white' }}
                              _checked={{ _focus: { boxShadow: 'none' } }}
                            >
                              <Text fontSize="15px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Reduced DSO
                              </Text>
                            </Checkbox>
                            <Checkbox value="savedTime" py="4px">
                              <Text fontSize="15px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Saved Time
                              </Text>
                            </Checkbox>
                            <Checkbox value="reducedBadDebt" py="4px">
                              <Text fontSize="15px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Reduced Bad Debt
                              </Text>
                            </Checkbox>
                          </Stack>
                        </CheckboxGroup>
                      </Flex>
                      <Button variant="solid" width="100%" py="8px" onClick={onCloseOutcome}>
                        Apply
                      </Button>
                    </VStack>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
              <Drawer placement="bottom" onClose={onCloseFeature} isOpen={isOpenFeature}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerBody px="24px" py="16px" backgroundColor={theme.colors.neutral[100]}>
                    <VStack>
                      <Flex justifyContent="space-between" py="8px" width="100%">
                        <Flex alignItems="center">
                          <DynamicIcon icon="AwardStar" width="16px" height="16px" />
                          <Text fontWeight="500" fontSize="15px" lineHeight="20px" color="black" pl="12px">
                            Feature
                          </Text>
                        </Flex>
                        <Button
                          variant="link"
                          onClick={() => {
                            onCloseFeature();
                            resetFilters();
                          }}
                        >
                          Cancel
                        </Button>
                      </Flex>
                      <Flex width="100%" py="8px">
                        <CheckboxGroup
                          value={filters.featureFilter}
                          onChange={(value) => handleFilterChange('featureFilter', value as string[])}
                        >
                          <Stack direction="column">
                            <Checkbox value="Collections" py="4px">
                              <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Collections
                              </Text>
                            </Checkbox>
                            <Checkbox value="Analytics" py="4px">
                              <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Analytics
                              </Text>
                            </Checkbox>
                            <Checkbox value="Payments" py="4px">
                              <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Payments
                              </Text>
                            </Checkbox>
                          </Stack>
                        </CheckboxGroup>
                      </Flex>
                      <Button variant="solid" width="100%" py="8px" onClick={onCloseFeature}>
                        Apply
                      </Button>
                    </VStack>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
              <Drawer placement="bottom" onClose={onCloseSize} isOpen={isOpenSize}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerBody px="24px" py="16px" backgroundColor={theme.colors.neutral[100]}>
                    <VStack>
                      <Flex justifyContent="space-between" py="8px" width="100%">
                        <Flex alignItems="center">
                          <DynamicIcon icon="Groups" width="16px" />
                          <Text fontWeight="500" fontSize="15px" lineHeight="20px" color="black" pl="12px">
                            Size
                          </Text>
                        </Flex>
                        <Button
                          variant="link"
                          onClick={() => {
                            onCloseSize();
                            resetFilters();
                          }}
                        >
                          Cancel
                        </Button>
                      </Flex>
                      <Flex width="100%" py="8px">
                        <RadioGroup
                          value={filters.sizeFilter[0] || ''}
                          onChange={(value) => handleFilterChange('sizeFilter', value as string)}
                        >
                          <Stack direction="column">
                            <Box onClick={() => handleSizeFilterClick('upTo50')} cursor="pointer">
                              <Radio value="upTo50" py="4px" onClick={() => handleSizeFilterClick('upTo50')}>
                                <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B" onClick={() => handleSizeFilterClick('upTo50')}>
                                  Up to 50 employees
                                </Text>
                              </Radio>
                            </Box>
                            <Box onClick={() => handleSizeFilterClick('50to200')} cursor="pointer">
                              <Radio value="50to200" py="4px" onClick={() => handleSizeFilterClick('50to200')}>
                                <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B" onClick={() => handleSizeFilterClick('50to200')}>
                                  51-200 employees
                                </Text>
                              </Radio>
                            </Box>
                            <Box onClick={() => handleSizeFilterClick('201to500')} cursor="pointer">
                              <Radio value="201to500" py="4px" onClick={() => handleSizeFilterClick('201to500')}>
                                <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B" onClick={() => handleSizeFilterClick('201to500')}>
                                  201-500 employees
                                </Text>
                              </Radio>
                            </Box>
                            <Box onClick={() => handleSizeFilterClick('over500')} cursor="pointer">
                              <Radio value="over500" py="4px" onClick={() => handleSizeFilterClick('over500')}>
                                <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B" onClick={() => handleSizeFilterClick('over500')}>
                                  Over 500 employees
                                </Text>
                              </Radio>
                            </Box>
                          </Stack>
                        </RadioGroup>
                      </Flex>
                      <Button variant="solid" width="100%" py="8px" onClick={onCloseSize}>
                        Apply
                      </Button>
                    </VStack>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
              <Drawer placement="bottom" onClose={onCloseIndustry} isOpen={isOpenIndustry}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerBody px="24px" py="16px" backgroundColor={theme.colors.neutral[100]}>
                    <VStack>
                      <Flex justifyContent="space-between" py="8px" width="100%">
                        <Flex alignItems="center">
                          <DynamicIcon icon="Factory" width="16px" />
                          <Text fontWeight="500" fontSize="15px" lineHeight="20px" color="black" pl="12px">
                            Industry
                          </Text>
                        </Flex>
                        <Button
                          variant="link"
                          onClick={() => {
                            onCloseIndustry();
                            resetFilters();
                          }}
                        >
                          Cancel
                        </Button>
                      </Flex>
                      <Flex width="100%" py="8px">
                        <CheckboxGroup
                          value={filters.industryFilter}
                          onChange={(value) => handleFilterChange('industryFilter', value as string[])}
                        >
                          <Stack direction="column">
                            <Checkbox value="marketingTech" py="4px">
                              <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Marketing Tech
                              </Text>
                            </Checkbox>
                            <Checkbox value="hrTech" py="4px">
                              <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                HR Tech
                              </Text>
                            </Checkbox>
                            <Checkbox value="financeTech" py="4px">
                              <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Finance Tech
                              </Text>
                            </Checkbox>
                            <Checkbox value="ecommerceTech" py="4px">
                              <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Ecommerce Tech
                              </Text>
                            </Checkbox>
                            <Checkbox value="productManagementTech" py="4px">
                              <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Product Management Tech
                              </Text>
                            </Checkbox>
                            <Checkbox value="manufacturingAndLogistics" py="4px">
                              <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Manufacturing & Logistics
                              </Text>
                            </Checkbox>
                            <Checkbox value="transportation" py="4px">
                              <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Transportation
                              </Text>
                            </Checkbox>
                            <Checkbox value="storageSolutions" py="4px">
                              <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Storage Solutions
                              </Text>
                            </Checkbox>
                            <Checkbox value="hrConsulting" py="4px">
                              <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                HR Consulting
                              </Text>
                            </Checkbox>
                            <Checkbox value="fitness" py="4px">
                              <Text fontSize="14px" fontWeight="400" lineHeight="20px" color="#0B254B">
                                Fitness
                              </Text>
                            </Checkbox>
                          </Stack>
                        </CheckboxGroup>
                      </Flex>
                      <Button variant="solid" width="100%" py="8px" onClick={onCloseIndustry}>
                        Apply
                      </Button>
                    </VStack>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </Box>
          )}

          {/* Render Filtered Stories */}
          {
            !isMobile
              ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="16px"
                  width="full"
                >
                  <Pagination
                    length={Math.ceil(filteredStories.length / 6)}
                    value={page}
                    onChange={setPage}
                  />
                  <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                    {map(filteredStories.slice(page * 6, (page + 1) * 6), (story: any) => (
                      <Box key={story._uid}>
                        <DynamicComponent block={story} />
                      </Box>
                    ))}
                  </Grid>
                </Box>
              ) : (
                <Flex
                  width="100%"
                  wrap="nowrap"
                  overflowX="scroll"
                  sx={{
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    '-ms-overflow-style': 'none',
                    'scrollbar-width': 'none',
                  }}
                  gap={{ base: '24px', desktop: '38px' }}
                >
                  {map(filteredStories, (story: any) => (
                    <Box key={story._uid}>
                      <DynamicComponent block={story} />
                    </Box>
                  ))}
                </Flex>
              )}
        </Flex>
      </Container>
    </Box>
  );
};

export default CustomerStoriesFilters;
